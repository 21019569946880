export const Additional = () => {
  return (
    <div className="additional">
      <p className="additional__title">Go'shimcha ma'lumot</p>
      <div className="additional__wrapper additional__wrapper--mobile">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Yangilangan</p>
            <p className="additional__description">17.07.24</p>
          </div>

          <div className="additional__item">
            <p className="additional__text">Oʻrnatilgan</p>
            <p className="additional__description">20794</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Yosh</p>
            <p className="additional__description">21+</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Hajmi</p>
            <p className="additional__description">3.3MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Joriy versiya</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Dasturchi</p>
            <p className="additional__description">NBU</p>
          </div>
        </div>
      </div>
      <div className="additional__wrapper additional__wrapper--desktop">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Yangilangan</p>
            <p className="additional__description">17.07.24</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Hajmi</p>
            <p className="additional__description">3.3MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Oʻrnatilgan</p>
            <p className="additional__description">20794</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Joriy versiya</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Yosh</p>
            <p className="additional__description">21+</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Dasturchi</p>
            <p className="additional__description">NBU</p>
          </div>
        </div>
      </div>
    </div>
  );
};
