export const New = () => {
    return (
        <div className="new">
            <p className="new__title">Qanday yangiliklar?</p>
            <p className="new__description">Bonuslar va aktsiyalar bo'limi yangilandi</p>
            <p className="new__description">
                Xaritada eng yaqin filial, bankomat yoki terminalning joylashuvi qo'shildi.
            </p>
            <p className="new__description">
                Face ID va Touch ID qo‘llab-quvvatlashi qo‘shildi.
            </p>
            <p className="new__description">
                Ba'zi Android 10 foydalanuvchilari uchun push-bildirishnomalar bilan bog'liq muammo tuzatildi.
            </p>
            <p className="new__description">
                Biz Android foydalanuvchilari uchun Sozlamalardagi matn juda kichik bo‘lgan muammoni tuzatdik.
            </p>
        </div>
    );
};
