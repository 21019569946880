import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import user from "../../assets/user.png";
import {useState} from "react";

export const Reviews = () => {
    const [showReview, setShowReview] = useState(false);
    return (
        <>
            <p className="reviews__title">Reyting va sharhlar
            </p>
            <div className="reviews__wrapper">
                <div>
                    <p className="reviews__score">4.8</p>
                    <div className="star__wrapper">
                        <img src={starGreen} alt="green star" className="green__star"/>
                        <img src={starGreen} alt="green star" className="green__star"/>
                        <img src={starGreen} alt="green star" className="green__star"/>
                        <img src={starGreen} alt="green star" className="green__star"/>
                        <img src={starGrey} alt="grey star" className="green__star"/>
                    </div>
                    <p className="user__wrapper">
                        Общий
                        <img src={user} alt="user icon" width={16} height={16}/>
                    </p>
                    <p className="reviews__number">2697</p>
                </div>
                <div>
                    <ul className="lines__list">
                        <li className="lines__item">
                            <p className="numbers">5</p>
                            <div className="first__line desktop_one">
                                <div className="first__line--green"></div>
                            </div>
                        </li>
                        <li className="lines__item">
                            <p className="numbers">4</p>
                            <div className="first__line desktop_two">
                                <div className="second__line--green"></div>
                            </div>
                        </li>

                        <li className="lines__item">
                            <p className="numbers">3</p>
                            <div className="first__line desktop_three">
                                <div className="third__line--green"></div>
                            </div>
                        </li>

                        <li className="lines__item">
                            <p className="numbers">2</p>
                            <div className="first__line desktop_four">
                                <div className="fourth__line--green"></div>
                            </div>
                        </li>

                        <li className="lines__item">
                            <p className="numbers">1</p>
                            <div className="first__line desktop_five">
                                <div className="fourth__line--green"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="item__wrapper">
                <img
                    src={review1}
                    alt="logo"
                    width={48}
                    height={48}
                    className="item__logo"
                />
                <div>
                    <div className="item__flex">
                        <div>
                            <div className="item__thumb">
                                <p className="item__title">Erwat Qodirov</p>
                                <div className="images__wrapper">
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                </div>
                            </div>
                            <p className="item__date">30.04.2024</p>
                        </div>
                        {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
                    </div>
                    <p className="item__text">
                        5 kun o'ynab 2 506 900 so'm ishlab oldim, juda zo'r! Ajoyib kazino uchun rahmat!
                    </p>
                    <div className="review__box">
                        <div className="triangle"></div>
                        <p className="review__title">NBU Casino</p>
                        <p className="review__description">
                            Erwat Qodirov fikr-mulohazangiz uchun rahmat! Mijozlarimiz loyihamizdan zavq olishlari biz
                            uchun juda muhim.
                        </p>
                    </div>
                </div>
            </div>
            <div className="item__wrapper">
                <img
                    src={review2}
                    alt="logo"
                    width={48}
                    height={48}
                    className="item__logo"
                />
                <div>
                    <div className="item__flex">
                        <div>
                            <div className="item__thumb">
                                <p className="item__title">Zakir Turdiyev</p>
                                <div className="images__wrapper">
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                </div>
                            </div>
                            <p className="item__date">29.04.2024</p>
                        </div>
                        {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
                    </div>
                    <p className="item__text">
                        15 000 so'mga gapim yo'q bir necha soatda 2 000 000 oldim ;)) Ko'pdan beri omadim yo'q edi. Men
                        o'ynagan eng yaxshi kazino!
                    </p>
                    <div className="review__box">
                        <div className="triangle"></div>
                        <p className="review__title">NBU Casino</p>
                        <p className="review__description">
                            Zakir Turdiyev
                            Ijobiy fikr-mulohazalaringiz uchun tashakkur. Biz mijozlarimizni qondirishga harakat
                            qilamiz!
                        </p>
                    </div>
                </div>
            </div>
            <div className="item__wrapper">
                <img
                    src={review3}
                    alt="logo"
                    width={48}
                    height={48}
                    className="item__logo"
                />
                <div>
                    <div className="item__flex">
                        <div>
                            <div className="item__thumb">
                                <p className="item__title">Rahim Rahimov</p>
                                <div className="images__wrapper">
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                    <img
                                        src={starGreen}
                                        alt="star green"
                                        className="green__star"
                                    />
                                </div>
                            </div>
                            <p className="item__date">28.04.2024</p>
                        </div>
                        {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
                    </div>
                    <p className="item__text">
                        Men odatda bu kazinolarning barchasiga ishonmayman! Ammo bu dastur aynan shunday! Men buni
                        qiziquvchanlik uchun sinab ko'rdim va 15 daqiqada 10 000 000 yutib oldim. Men o'ynashda va pul
                        ishlashda davom etaman!
                    </p>
                    <div className="review__box">
                        <div className="triangle"></div>
                        <p className="review__title">NBU Casino</p>
                        <p className="review__description">
                            Rahim Rahimov
                            yaxshi sharh uchun rahmat! O'ynang va zavqlaning!
                        </p>
                    </div>
                </div>
            </div>
            {showReview && (
                <>
                    <div className="item__wrapper">
                        <img
                            src={review4}
                            alt="logo"
                            width={48}
                            height={48}
                            className="item__logo"
                        />
                        <div>
                            <div className="item__flex">
                                <div>
                                    <div className="item__thumb">
                                        <p className="item__title">Shovruk Dadajonov</p>
                                        <div className="images__wrapper">
                                            <img
                                                src={starGreen}
                                                alt="star green"
                                                className="green__star"
                                            />
                                            <img
                                                src={starGreen}
                                                alt="star green"
                                                className="green__star"
                                            />
                                            <img
                                                src={starGreen}
                                                alt="star green"
                                                className="green__star"
                                            />
                                            <img
                                                src={starGreen}
                                                alt="star green"
                                                className="green__star"
                                            />
                                            <img
                                                src={starGreen}
                                                alt="star green"
                                                className="green__star"
                                            />
                                        </div>
                                    </div>
                                    <p className="item__date">10.04.2024</p>
                                </div>
                                {/* <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div> */}
                            </div>
                            <p className="item__text">
                                Yigitlardan bunday ajoyib loyiha qilishlarini kutmagandim!Hayotimdagi eng saxiy kazino!
                            </p>
                            <div className="review__box">
                                <div className="triangle"></div>
                                <p className="review__title">NBU Casino</p>
                                <p className="review__description">
                                    Fikr-mulohazangiz uchun rahmat. Bizning loyihamiz faqat odamlarga his-tuyg'ularni
                                    berish uchun yaratilgan. Sizni tez-tez xursand qilishga harakat qilamiz! Omad sizga!
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">08.04.2024</p>
                </div> */}
                    {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
                    {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                казино!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Казино</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
                    {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
                    {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее казино в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Казино</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
                </>
            )}
            <button
                type="button"
                className="reviews__button"
                onClick={() => {
                    setShowReview((prevState) => !prevState);
                }}
            >
                {showReview ? "Sharhlarni yashirish" : "Barcha sharhlar"}
            </button>
        </>
    );
};
